import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import loginBackground from '../assets/calendar.png';

function Id({ fetchUser }) {
    const [nationalId, setNationalId] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleNationalIdChange = (e) => {
        setNationalId(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const success = await fetchUser(nationalId, password);
        if (success) {
            localStorage.setItem('id', nationalId);
            localStorage.setItem('pass', password);
            navigate(-1);
        } else {
            Swal.fire({
                icon: "error",
                title: "خطأ",
                text: "رقم الهوية الوطنية أو كلمة المرور غير صحيحة",
                confirmButtonText: `
                <i class="fa fa-thumbs-up"></i> حسناً
              `,
            });
        }
    };

    return (
        <div className="flex min-h-[calc(100vh-5rem)] border-t-4">
            {/* <!-- left column container with form --> */}
            <div className="flex items-center justify-center flex-col w-full md:w-[50%] bg-blue-200 md:bg-white pb-10">
                <h1 className="text-3xl my-6">تسجيل الدخول</h1>

                <form className="w-[70%]" onSubmit={handleSubmit}>

                    <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="nationalId"
                            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-600 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="أدخل رقم الهوية الوطنية"
                            value={nationalId}
                            onChange={handleNationalIdChange}
                        />
                    </div>
                    <div className="relative my-2">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
                            </svg>
                        </div>
                        <input
                            type="password"
                            id="password"
                            className="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-600 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="كلمة السر"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <button
                        type="submit"
                        className="text-white w-[40%] mt-3 mx-auto bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-3 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        تسجيل الدخول
                    </button>
                </form>

                <div className='w-[80%] flex justify-center bg-yellow-100 mt-6 rounded-xl shadow-xl'>
                    <div className='my-5 mx-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126ZM12 15.75h.007v.008H12v-.008Z" />
                        </svg>
                    </div>
                    <p className="text-lg my-4 text-center w-[80%]">
                        ملحوظه: يجب أن يكون رقم الهوية الوطنية مسجل لدينا لكي تتمكن من الحجز. لذلك يجب الذهاب للمركز مرة واحدة على الأقل أو الاتصال بنا لتسجيل بياناتك.
                    </p>
                </div>
            </div>
            {/* <!-- Right column container with background--> */}
            <div className="hidden md:flex overflow-hidden items-end justify-center bg-blue-600 w-full md:w-[50%]" style={{ borderRadius: '0 10% 0% 0px' }}>
                <div className="md:w-12/12 relative">
                    <img src={loginBackground} className="h-[600px]" alt="Phone image" />
                </div>
            </div>
        </div>
    );
}

export default Id;
