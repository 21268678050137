import React from "react";
import MarshalUrl, { BaseUrl } from "../API/config";
import { Button } from "@material-tailwind/react";
import { useState } from "react";

function PaymentDetails({
  handlePrev,
  selectDate,
  selectService,
  selectDoctor,
  selectClinic,
  user,
}) {
  const servicePrice = selectService[5];
  const tax = user[0][3] !== 1 ? selectService[14] : 0;
  const totlaPrice = Math.ceil(
    servicePrice +
    servicePrice * (Number(tax) / 100) +
    (servicePrice + servicePrice * (Number(tax) / 100)) * (2.9 / 100) +
    1 +
    1
  );
  const reservationDetails = {
    DDATE: selectDate.date,
    DTIME: selectDate.time,
    PAT_NO: user[0][0],
    PAT_NAME: user[0][1],
    CLINIC_CODE: selectClinic.value,
    DOCTOR: selectDoctor.value,
    DSTATE: 1,
    TEL: user[0][17],
    INVNO: null,
    INVDATE: null,
    USER_CODE: 1305,
    DNOTE: "حجز اونلاين",
    DTYPE: null,
    NAT: user[0][3],
    BIRTHDAY: null,
    SEX: 1,
    DTYPE_SUB: null,
    COME_TIME: null,
    FINISH_TIME: null,
    DKIND: 3,
    DPERIOD: 30,
    SERVICE_SUB: selectService[2],
    SER_NAME: null,
  };

  const sandQabdDetails = {
    RET: 1,
    DWAY: 2,
    DDATE: new Date(Date.now()),
    DTIME: new Date(),
    CUSTCODE: user[0][0],
    CUSTNAME: user[0][1],
    CLINIC_CODE: selectClinic.value,
    DOCTOR: selectDoctor.value,
    DVALUE: Math.ceil(servicePrice + servicePrice * (Number(tax) / 100)),
    DESCR: "حجز اونلاين",
    INVNO: null,
    USER_CODE: 1305,
    ADM_CODE: null,
    PROG_CODE: null,
    IS_OK: null,
    BATCH_NO: null,
  };
  const handleSubmit = async () => {
    try {
      const response = await MarshalUrl.post("/pay", {
        cart: {
          id: "1",
          currency: "SAR",
          amount: totlaPrice,
          description: "Product Description",
        },
        customer: user[0],
        url: {
          callback: `${BaseUrl}/payment/callback`,
          // response: `${BaseUrl}/payment/return`,
        },
        sandQabdDetails,
        reservationDetails,
      });
      const data = response.data;
      if (data.url) {
        // Redirect user to PayTabs payment page
        window.location.href = data.url;
      } else {
        console.log("Payment setup failed. Please try again.");
      }
    } catch (error) {
      console.log("Payment setup failed. Please try again.");
    }
  };

  return (
    <div>
      <div className="flex h-auto max-w-[95%] w-full md:w-[700px] m-auto text-white">
        <div className="w-full bg-blue-800 rounded-2xl p-5">
          <h1 className="text-2xl md:text-3xl  text-center mb-3">
            معلومات الحجز:
          </h1>
          <div className="overflow-x-auto">
            <table
              className="w-full text-right table-auto "
              style={{ borderCollapse: "separate" }}
            >
              <tbody>
                <tr className="my-2">
                  <td className=" py-2 rounded-tr-2xl px-4 font-bold bg-white text-blue-800">
                    خدمة
                  </td>
                  <td className=" py-2 rounded-tl-2xl px-4 bg-white text-blue-800">
                    {selectService[4]}
                  </td>
                </tr>
                <tr className="my-2">
                  <td className=" py-2 px-4  font-bold bg-white text-blue-800">
                    العيادة والدكتور{" "}
                  </td>
                  <td className=" py-2 px-4 bg-white text-blue-800">
                    دكتور: {selectDoctor.label} {" - "}عيادة :
                    {selectClinic.label}
                  </td>
                </tr>
                <tr className="my-2">
                  <td className=" py-2 px-4 font-bold bg-white text-blue-800">
                    تاريخ الحجز
                  </td>
                  <td className=" py-2 px-4 bg-white text-blue-800">
                    {selectDate.date} - {selectDate.timeAMPM}
                  </td>
                </tr>
                <tr className="my-2">
                  <td className=" py-2 px-4 font-bold bg-white text-blue-800">
                    سعر
                  </td>
                  <td className=" py-2 px-4 bg-white text-blue-800">
                    {selectService[5]} ريال سعودي
                  </td>
                </tr>

                {user[0][3] !== 1 ? (
                  <tr className="my-2">
                    <td className=" py-2 px-4 font-bold bg-white text-blue-800">
                      ضريبة
                    </td>
                    <td className=" py-2 px-4 bg-white text-blue-800">
                      {Number(selectService[14])}%
                    </td>
                  </tr>
                ) : (
                  ""
                )}

                <tr className="my-2">
                  <td className=" py-2 px-4 font-bold bg-white text-blue-800">
                    تكلفة الخدمة
                  </td>
                  <td className=" py-2 px-4 bg-white text-blue-800">
                    2.9% + 1 ريال سعودى
                  </td>
                </tr>
                <tr className="my-2">
                  <td className=" py-2 rounded-br-2xl px-4 font-bold bg-yellow-200 text-blue-800">
                    اجمالي المبلغ
                  </td>
                  <td className=" py-2 rounded-bl-2xl px-4 bg-yellow-200 text-blue-800">
                    {totlaPrice} ريال سعودي
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <Button
          className="mx-5 bg-blue-800 px-5 h-14 w-28 font-2xl"
          onClick={handleSubmit}
        >
          ادفع الان
        </Button>
        <Button
          className="mx-5 bg-blue-800 px-5 h-14 w-28 font-2xl"
          onClick={handlePrev}
        >
          السابق
        </Button>
      </div>
    </div>
  );
}

export default PaymentDetails;
