import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useFormContext } from "react-hook-form";

function Doctor({
  user,
  doctors,
  clinics,
  selectClinic,
  doctorRealtions,
  selectedDocAndClinin,
  selectDoctor,
  setIsValidStep,
}) {
  const {
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const [selectedClinic, setSelectedClinic] = useState(selectClinic || null);
  const [selectedDoctor, setSelectedDoctor] = useState(selectDoctor || null);
  const [filterClinics, setFilterClinics] = useState([]);
  const [clinicSelectDisabled, setClinicSelectDisabled] = useState(true);

  const handleChangeDoctor = (selectedOption) => {
    setSelectedDoctor(selectedOption);
    setValue("doctorStep.selectDoctor", selectedOption);
    trigger("doctorStep.selectDoctor");

    const current = [];
    const clinicsRelation = doctorRealtions.filter(
      (relation) => relation[1] === selectedOption?.value
    );

    clinics.forEach((clinicCurrent) => {
      clinicsRelation.forEach((docRelation) => {
        if (clinicCurrent[0] === docRelation[0]) {
          current.push(clinicCurrent);
        }
      });
    });

    const filteredClinics = current.map((clinic) => ({
      value: clinic[0],
      label: clinic[2],
    }));

    setFilterClinics(filteredClinics);

    if (filteredClinics.length > 0) {
      const firstClinic = filteredClinics[0];
      setSelectedClinic(firstClinic);
      setValue("doctorStep.selectClinic", firstClinic);
      trigger("doctorStep.selectClinic");
      setClinicSelectDisabled(true);
    } else {
      setSelectedClinic(null);
      setClinicSelectDisabled(false);
    }
  };

  const handleChangeClinic = (selectedOption) => {
    setSelectedClinic(selectedOption);
    setValue("doctorStep.selectClinic", selectedOption);
    trigger("doctorStep.selectClinic");
  };

  const filterDoctors = doctors.map((doctor) => ({
    value: doctor[0],
    label: doctor[2],
  }));

  useEffect(() => {
    if (selectedClinic && selectedDoctor && selectedDoctor.value) {
      selectedDocAndClinin(selectedDoctor, selectedClinic);
    }

    const validateStep = async () => {
      const isValid = await trigger([
        "doctorStep.selectClinic",
        "doctorStep.selectDoctor",
      ]);
      setIsValidStep(isValid);
    };

    validateStep();
  }, [selectedDoctor, selectedClinic, trigger, setIsValidStep]);

  return (
    <div className="flex h-[450px] max-w-[95%] w-[700px] m-auto text-white">
      <div className="flex items-center justify-center flex-col w-full md:w-[100%] bg-blue-800 rounded-2xl p-5">
        <h1 className="text-3xl mb-6 text-center">
          مرحبا, {user ? user[0][1] : ""}
        </h1>

        <p className="text-lg my-4">
          من فضلك قم باختيار العيادة والدكتور المناسب لخدمتك
        </p>

        <Select
          value={selectedDoctor}
          onChange={handleChangeDoctor}
          options={filterDoctors}
          isSearchable
          className="min-w-[300px] my-4 border-[1px] shadow-lg rounded-[4px] border-black text-black"
          placeholder="اختر الطبيب......"
        />
        {errors.doctorStep?.selectDoctor && (
          <span className="text-red-500">
            {errors.doctorStep.selectDoctor.message}
          </span>
        )}

        <Select
          value={selectedClinic}
          onChange={handleChangeClinic}
          options={filterClinics}
          isSearchable
          className="min-w-[300px] my-4 border-[1px] shadow-lg rounded-[4px] border-black text-black"
          placeholder="اختر العيادة......"
          isDisabled={clinicSelectDisabled}
        />
        {errors.doctorStep?.selectClinic && (
          <span className="text-red-500">
            {errors.doctorStep.selectClinic.message}
          </span>
        )}
      </div>
    </div>
  );
}

export default Doctor;
