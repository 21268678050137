import axios from "axios";

// export const BaseUrl = 'http://localhost:5000'
export const BaseUrl = "https://albader.dyndns.org/api";
const MarshalUrl = axios.create({
  baseURL: BaseUrl,
});
//MarshalUrl.interceptors.request.use(
  //(config) => {
   // config.headers = { "ngrok-skip-browser-warning": "true" };
    //return config;
  //},
  //(error) => {
    //console.log(error);
    //return Promise.reject(error);
  //}
//);

export default MarshalUrl;
