import { Button, Step, Stepper } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Calender from "./Calender";
import Doctor from "./Doctor";
import PaymentDetails from "./PaymentDetails";
import SelectedService from "./SelectedService";

// Define validation schemas for each step
const doctorSchema = yup.object().shape({
  doctorStep: yup.object().shape({
    selectDoctor: yup.object().nullable().required("الرجاء اختر الطبيب"),
    selectClinic: yup.object().nullable().required("الرجاء اختر العياده"),
  }),
});

const calenderSchema = yup.object().shape({
  calenderStep: yup.object().shape({
    selectDate: yup.date().required("الرجاء اختر موعد الحجز"),
  }),
});

const schemas = [
  yup.object().shape({}), // Schema for the first step if needed
  doctorSchema,
  calenderSchema,
  yup.object().shape({}), // Schema for payment step if needed
];

function ReservationStepper({
  selectDate,
  setSelectDate,
  user,
  selectClinic,
  selectDoctor,
  doctors,
  clinics,
  doctorRealtions,
  selectedDocAndClinin,
  selectService,
  setBook,
  books,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [isValidStep, setIsValidStep] = useState(true);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(schemas[activeStep]),
  });

  const handleNext = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      !isLastStep && setActiveStep((cur) => cur + 1);
    }
  };

  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  if ((user && user?.length === 0) || !localStorage.getItem("id")) {
    navigate("/login");
  } else if (
    user &&
    user?.length !== 0 &&
    localStorage.getItem("id") &&
    !selectService
  ) {
    navigate("/services");
  } else {
    return (
      <FormProvider {...methods}>
        <div className="w-[90%] py-4  m-auto ">
          <Stepper
            className="text-start flex flex-row-reverse"
            activeStep={activeStep}
            isLastStep={(value) => setIsLastStep(value)}
            isFirstStep={(value) => setIsFirstStep(value)}
          >
            {[1, 2, 3, 4].map((step, index) => (
              <Step
                className="h-8 w-8"
                key={step}
                // onClick={() => setActiveStep(index)} phase two
              >
                {step}
              </Step>
            ))}
          </Stepper>
          <div className="text-center mt-5 text-lg font-semibold">
            {activeStep === 0 && (
              <SelectedService
                selectService={selectService}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 1 && (
              <Doctor
                user={user}
                doctors={doctors}
                clinics={clinics}
                selectDoctor={selectDoctor}
                selectClinic={selectClinic}
                doctorRealtions={doctorRealtions}
                selectedDocAndClinin={selectedDocAndClinin}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 2 && (
              <Calender
                setSelectDate={setSelectDate}
                selectDate={selectDate}
                user={user}
                selectDoctor={selectDoctor}
                books={books}
                selectClinic={selectClinic}
                setBook={setBook}
                setIsValidStep={setIsValidStep}
              />
            )}
            {activeStep === 3 && (
              <PaymentDetails
                handlePrev={handlePrev}
                selectDate={selectDate}
                selectService={selectService}
                selectDoctor={selectDoctor}
                selectClinic={selectClinic}
                user={user}
              />
            )}
          </div>
          <div className="mt-5 flex justify-center">
            {isLastStep ? (
              ""
            ) : (
              <>
                <Button
                  className="mx-5 bg-blue-800 px-5 h-14 w-28 font-2xl"
                  onClick={handleNext}
                  disabled={isLastStep || !isValidStep}
                >
                  التالي
                </Button>
                <Button
                  className="mx-5 bg-blue-800 px-5 h-14 w-28 font-2xl"
                  onClick={handlePrev}
                  disabled={isFirstStep}
                >
                  السابق
                </Button>
              </>
            )}
          </div>
        </div>
      </FormProvider>
    );
  }
}

export default ReservationStepper;
