import React, { useState, useEffect } from "react";
import axios from "axios";
import Loader from "./Loader";
import MarshalUrl from "../API/config";
import { useNavigate } from "react-router";

function BookingDate({ user }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await MarshalUrl.get(`/userBooksToday/${user[0][0]}`); // Replace with your actual API endpoint
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs once when the component mounts

  if (isLoading) {
    return <Loader />;
  }

  function addOneDay(dateString) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function displayTimeWithOffset(dateTimeString, offsetHours = 3) {
    const date = new Date(dateTimeString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    hours = (hours + offsetHours) % 24;

    const hour12 = hours % 12 || 12;
    const period = hours < 12 ? "ص" : "م";

    return `${hour12.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;
  }

  return (
    <div className="min-h-[calc(100vh-5rem)]  bg-white">
      <div className="w-full  p-5">
        <div className="flex gap-2 flex-wrap justify-center">
          {data?.length === 0 || !data ? (
            <p className="text-xl font-bold text-center text-blue-800">
              لا يوجد حجوزات لك
            </p>
          ) : (
            data?.map((book) => (
              <div
                key={book[0]}
                className="bg-white h-auto w-full md:w-1/4 m-5 rounded-2xl p-5 shadow-lg transition-transform transform hover:scale-105"
              >
                <h1 className="text-xl bg-blue-200 font-bold py-4 text-center rounded-2xl text-blue-900">
                  تفاصيل الحجز
                </h1>

                <div>
                  <p className="text-lg my-2 font-bold text-center text-gray-600">
                    {book[24]}
                  </p>
                </div>

                <div>
                  <p className="text-lg my-2 font-bold text-center text-gray-600">
                    {book[25]}
                  </p>
                </div>

                <div className="flex justify-evenly">
                  <div>
                    <p className="text-lg my-2 font-bold text-center text-gray-600">
                      <strong>تاريخ :</strong>{" "}
                      {addOneDay(book[1].split("T")[0])}
                    </p>
                  </div>

                  <div>
                    <p className="text-lg my-2 font-bold text-center text-gray-600">
                      <strong>الوقت :</strong> {displayTimeWithOffset(book[2])}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default BookingDate;
