import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineArrowUp, AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router";
import MarshalUrl from "../API/config";
import icon from "../assets/services.png";

const Services = ({ setService, user }) => {
  const [services, setServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const observer = useRef();
  const navigate = useNavigate();

  const fetchServices = async (page, search = " ") => {
    try {
      const { data } = await MarshalUrl(
        `services?page=${page}&limit=10&search=${search}`
      );
      if (page === 1) setServices(data);
      // Reset and set new data for new search or initial load
      else setServices((prevServices) => [...prevServices, ...data]); // Append data for infinite scroll
      setHasMore(data?.length > 0);
    } catch (error) {
      console.error("Failed to fetch services:", error);
    }
  };

  // Effect for initial fetch and subsequent re-fetches when currentPage changes
  useEffect(() => {
    fetchServices(currentPage, searchTerm);
  }, [currentPage, searchTerm]); // Ensure to re-fetch when search term changes

  // Effect for handling search
  const handleSearch = (event) => {
    setCurrentPage(1); // Reset to first page when searching
    setSearchTerm(event.target.value); // Update search term
  };

  const lastServiceElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const handleClick = (service) => {
    setService(service);
    if (user?.length > 0 && localStorage.getItem("id")) {
      navigate("/reservation");
      return;
    } else {
      navigate("/login");
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container mx-auto px-4 mt-5">
      <div className="flex items-center justify-center py-4 w-[90%] md:w-[50%] mx-auto">
        <div className="relative  rounded-lg w-full border-2 border-blue-800 ">
          <AiOutlineSearch className="absolute top-2.5 left-3 text-blue-800 text-xl" />
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            placeholder="بحث عن خدمة"
            className="pl-10 pr-4 py-2   rounded-lg  w-[100%] focus:outline-none focus:ring focus:border-blue-300"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5">
        {services.map((service, index) => {
          const lastElement = services?.length === index + 1;
          return (
            <div
              key={service[2]}
              ref={lastElement ? lastServiceElementRef : null}
              className="relative m-10 flex w-full mx-auto max-w-xs flex-col justify-between overflow-hidden rounded-lg border-2 border-blue-800 bg-white shadow-2xl"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
              }}
            >
              <div>
                <div className="relative mx-3 mt-3 flex justify-center h-60 overflow-hidden rounded-xl">
                  <img src={icon} alt="product image" />
                </div>
                <div className="mt-4 px-5">
                  <h5 className="text-xl tracking-tight text-slate-900">
                    {service[4]}
                  </h5>
                  <div className="mt-2 mb-5 flex items-center justify-between">
                    <p>
                      <span className="text-3xl font-bold text-slate-900">
                        {service[5] === 0 ? "مجاني" : `${service[5]} ر.س`}
                      </span>
                    </p>
                    <div className="flex items-center">
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5 text-yellow-300"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                      </svg>
                      <span className="mr-2 ml-3 rounded bg-yellow-200 px-2.5 py-0.5 text-xs font-semibold">
                        5.0
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                onClick={() => handleClick(service)}
                className="flex cursor-pointer items-center justify-center rounded-md bg-blue-800 px-5 py-2.5 m-3 text-center text-sm font-medium text-white hover:bg-white border border-blue-800 hover:text-blue-800"
              >
                احجز الان
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
              </div>
            </div>
          );
        })}
      </div>
      <button
        onClick={scrollToTop}
        className="fixed bottom-5 right-5 z-50 flex items-center justify-center h-12 w-12 rounded-full bg-blue-800 text-white hover:bg-blue-700"
      >
        <AiOutlineArrowUp className="text-xl" />
      </button>
    </div>
  );
};

export default Services;
