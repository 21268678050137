import React, { useEffect, useState } from "react";
import axios from "axios";
import { Worker } from "@react-pdf-viewer/core";
// Import the main component
import { Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import { BaseUrl } from "../API/config";
import { useNavigate } from "react-router";
const Result = ({ user }) => {
  const [folderInfo, setFolderInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if ((user && user?.length === 0) || !localStorage.getItem("id")) {
      navigate("/login");
    } else {
      axios
        .post(`${BaseUrl}/my_result`, { USER_CODE: user[0][0] })
        .then((response) => {
          setFolderInfo(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
        });
    }
  }, []);

  const handleDownload = (file, folderName) => {
    // Replace this URL with the actual download endpoint for your files
    const downloadUrl = `${BaseUrl}/pat_pdf/${folderName}/${file}`;
    window.open(downloadUrl, "_blank");
  };

  return (
    <div className=" min-h-[100vh]">
      <div className=" mx-auto p-4 flex flex-wrap justify-center">
        {loading && (
          <div className=" flex justify-center items-center flex-wrap">
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
            <div
              role="status"
              class=" p-4  w-[350px] max-w-[90%] border-gray-200 rounded  animate-pulse md:p-6 shadow-md inline-block m-3 "
            >
              <div class="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded ">
                <svg
                  class="w-10 h-10 text-gray-200 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div class="h-2.5 bg-gray-200 rounded-full  w-48 mb-4"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
              <div class="h-2 bg-gray-200 rounded-full "></div>
            </div>
          </div>
        )}
        {!loading && folderInfo.length === 0 && (
          <div className="text-center w-full text-3xl my-10">
            {" "}
            لا يوجد نتائج لعرضها
          </div>
        )}
        {!loading &&
          folderInfo[0]?.files.map((file, index) => (
            <div
              key={index}
              className="bg-[#3F98FF] p-2 m-4 mb-0 rounded-2xl flex flex-col items-center shadow-lg max-w-[95%]"
              style={{
                boxShadow:
                  "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
              }}
            >
              <h3 className="text-lg font-semibold p-3 rounded-t-2xl bg-white w-[350px] max-w-[100%] text-center overflow-hidden text-nowrap break-words px-2">
                {file[0]}
              </h3>
              <div className="aspect-w-16 w-full">
                <Worker
                  className="w-full flex justify-center items-center"
                  workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
                >
                  <div className="w-full h-[280px] overflow-y-auto">
                    <Viewer
                      className="w-full"
                      fileUrl={`${BaseUrl}/get_pdf/${folderInfo[0].name}/${file[0]}`}
                    />
                  </div>
                </Worker>
              </div>
              <div className="flex justify-center py-3 bg-white font-bold w-full shadow-md">
                <div>
                  تاريخ النتيجة:{" "}
                  {new Date(file[2]).toLocaleDateString("ar", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </div>
              </div>
              <div className="flex w-full shadow-md">
                <button
                  onClick={() => handleDownload(file[0], folderInfo[0].name)}
                  className="bg-white w-full p-3 rounded-b-2xl text-[#3F98FF] font-semibold hover:bg-[#3F98FF] hover:text-white transition-all duration-300 ease-in-out"
                >
                  تنزيل الملف
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Result;
