import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function SelectedService({ selectService, setIsValidStep }) {
  const navigate =useNavigate()
  useEffect(() => {
    if(!selectService){
      navigate('/services')
    }
    setIsValidStep(true);
  }, []);

  return (
    <div className="flex  h-[450px] max-w-[95%] w-[700px] m-auto text-white">
      {/* <!-- left column container with form --> */}
      <div className="flex items-center justify-center flex-col w-full md:w-[100%]  bg-blue-800 rounded-2xl p-5">
        <h1 className="text-3xl font-bold mb-6 text-center">
          لقد اخترت خدمة :
        </h1>
        <h1 className="text-xl mb-6 font-bold text-center border p-4 rounded-xl">
          {selectService ? selectService[4] : ""}
        </h1>

        <p className=" text-lg  my-4">
          بسعر {selectService ? selectService[5] : ""} ريال سعودي غير شامل
          الضريبة
        </p>
      </div>
    </div>
  );
}

export default SelectedService;
