import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./Routers/Routes";
import MarshalUrl from "./API/config";
import Loader from "./Pages/Loader";
import Navbar from "./components/Navbar";

function App() {
  const [organizationName, setOrganizationName] = useState(null);
  const [doctors, setDoctors] = useState(null);
  const [clinics, setClinics] = useState(null);
  const [books, setBooks] = useState(null);
  const [services, setServices] = useState(null);
  const [doctorRealtions, setDoctorRealtions] = useState(null);
  const [user, setUser] = useState(null);
  const [selectDoctor, setSelectDoctor] = useState(null);
  const [selectClinic, setSelectclinic] = useState(null);
  const [selectService, SetselectService] = useState(null);
  const [selectDate, setSelectDate] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const organizationResponse = await MarshalUrl.get("/organization_name");
        setOrganizationName(organizationResponse.data);

        const doctorsResponse = await MarshalUrl.get("/doctors");
        setDoctors(doctorsResponse.data);

        const clinicsResponse = await MarshalUrl.get("/clinics");
        setClinics(clinicsResponse.data);

        const booksResponse = await MarshalUrl.get("/book");
        setBooks(booksResponse.data);

        const doctorRealtions = await MarshalUrl.get("/doctorsRelation");
        setDoctorRealtions(doctorRealtions.data);

        const servicesResponse = await MarshalUrl.get("/services");
        setServices(servicesResponse.data);

        setLoading(false); // Data fetching completed, set loading to false
      } catch (error) {
        setLoading(false); // If an error occurs, also set loading to false
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchUser = async (id,passWord) => {
    try {
      console.log(id,passWord)
      const fetchUser = await MarshalUrl.post(`/patient`,
      {
        password:passWord,
        id
      });
      setUser(fetchUser.data);
      return true;
    } catch (error) {
      console.error("Error fetching data:", error);
      return false;
    }
  };
  const selectedDocAndClinin = async (doc, clinic) => {
    setSelectDoctor(doc);
    setSelectclinic(clinic);
  };
  const setNewBook = (books) => {
    setBooks(books);
  };
  const setService = (service) => {
    SetselectService(service);
  };

  useEffect(() => {
    if (localStorage.getItem("id")) {
      fetchUser(localStorage.getItem("id"),localStorage.getItem("pass"));
    }
  }, []);
  return (
    <BrowserRouter>
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <AppRoutes
          organizationName={organizationName}
          doctors={doctors}
          clinics={clinics}
          books={books}
          services={services}
          user={user}
          selectDate={selectDate}
          setSelectDate={setSelectDate}
          fetchUser={fetchUser}
          doctorRealtions={doctorRealtions}
          selectedDocAndClinin={selectedDocAndClinin}
          selectClinic={selectClinic}
          selectDoctor={selectDoctor}
          selectService={selectService}
          setService={setService}
          setBooks={setNewBook}
        />
      )}
    </BrowserRouter>
  );
}

export default App;
