import { Route, Routes } from "react-router";
import Calender from "../Pages/Calender";
import Doctor from "../Pages/Doctor";
import Home from "../Pages/Home";
import Id from "../Pages/Id";
import ReservationStepper from "../Pages/ReservationStepper";
import ServicesPage from "../Pages/ServicesPage";
import Payment from "../Pages/paymentff";
import BookingDate from "../Pages/BookingDate";
import Result from "../Pages/Result";
import LoadingPage from "../components/LoadingPage";

export default function AppRoutes({
  selectDate,
  setSelectDate,
  setBook,
  organizationName,
  fetchUser,
  user,
  clinics,
  doctors,
  doctorRealtions,
  selectClinic,
  selectDoctor,
  selectedDocAndClinin,
  books,
  services,
  setService,
  selectService,
}) {
  return (
    <Routes>
      <Route path="/" element={<Home organizationName={organizationName} />} />
      <Route path="/books" element={<BookingDate user={user} />} />
      <Route path="/login" element={<Id fetchUser={fetchUser} />} />
      {/* <Route path="/reservation_doc" element={<Doctor   services={services} selectedDocAndClinin={selectedDocAndClinin} clinics={clinics} doctors={doctors} user={user} doctorRealtions={doctorRealtions}  selectDoctor={selectDoctor} selectClinic={selectClinic} books={books}/>} /> */}
      <Route path="/reservation_cal" element={<Calender books={books} />} />
      <Route path="/payment" element={<Payment />} />
      <Route
        path="/services"
        element={<ServicesPage setService={setService} user={user} />}
      />
      <Route
        path="/reservation"
        element={
          <ReservationStepper
            setSelectDate={setSelectDate}
            selectDate={selectDate}
            setBook={setBook}
            selectService={selectService}
            selectClinic={selectClinic}
            selectDoctor={selectDoctor}
            services={services}
            selectedDocAndClinin={selectedDocAndClinin}
            clinics={clinics}
            doctors={doctors}
            user={user}
            doctorRealtions={doctorRealtions}
            books={books}
          />
        }
      />
      <Route path="/results" element={<Result user={user} />} />
      <Route path="/loading/:status" element={<LoadingPage />} />
    </Routes>
  );
}
