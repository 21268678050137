import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [isHomePage, setIsHomePage] = useState(false); // State to determine if the current page is the home page
  const [showContactDropdown, setShowContactDropdown] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let isLogged = localStorage.getItem("id");

  const navItems = [
    { id: 1, text: "الرئيسية", to: "/" },
    { id: 2, text: "خدماتنا", to: "/services" },
    { id: 3, text: "استعلام عن حجوزاتى", to: "/books" },
    { id: 4, text: " نتائج المختبر", to: "/results" },
    { id: 6, text: "تسجيل الدخول", to: "/login" },
    { id: 7, text: "تسجيل الخروج", to: "/logout" },
  ];

  if (!isLogged) {
    navItems.splice(2, 2);
  }

  // Check the current path to toggle navbar color
  useEffect(() => {
    setIsHomePage(location.pathname === "/" || location.pathname === "/login");
  }, [location.pathname]);

  const handleNav = () => {
    setNav(!nav);
  };

  const toggleContactDropdown = () => {
    setShowContactDropdown(!showContactDropdown);
  };

  return (
    <div
      className={`${isHomePage ? "bg-white text-black" : "bg-[#0F58CE] text-white"
        } sticky top-0 flex z-50 justify-between items-center h-20 w-full px-4`}
    >
      <h1
        className={`text-2xl md:text-3xl font-extrabold ${isHomePage ? "text-[#0F58CE]" : "text-white"
          }`}
      >
        مجمع الدكتور بدر الطبي
      </h1>

      <ul className="hidden lg:flex ml-6 justify-start">
        {navItems.map((item) =>
          (item.id !== 6 && isLogged) || (item.id !== 7 && !isLogged) ? (
            <li
              key={item.id}
              onClick={() => {
                navigate(item.to);
                item.id === 7 && localStorage.removeItem("id");
              }}
              className={`p-3 hover:bg-white hover:text-[#0F58CE] font-[500] rounded-xl m-2 cursor-pointer text-lg duration-300 ${location.pathname === item.to ? "bg-white text-[#0F58CE]" : ""
                }`}
            >
              {item.text}
            </li>
          ) : (
            ""
          )
        )}
        <li className="relative">
          <button
            onClick={toggleContactDropdown}
            className="bg-[#0F58CE] mt-2 text-white px-6 py-3 rounded-lg text-lg hover:bg-white hover:text-[#0F58CE] font-bold transition-colors duration-300 ease-in-out"
          >
            تواصل معنا
          </button>
          {showContactDropdown && (
            <div className="absolute left-[0px] mt-2 min-w-[190px] bg-white text-[#0F58CE] p-2 rounded shadow-lg">
              <a
                href="https://wa.me/+966553742479"
                className="block px-4 min-w-fit py-2 hover:bg-[#0F58CE] hover:text-white rounded"
              >
                هاتف المجمع
              </a>
              <a
                href="https://wa.me/+966506028424"
                className="block px-4 py-2 hover:bg-[#0F58CE] hover:text-white rounded"
              >
                عيادة الجلدية والليزر
              </a>
            </div>
          )}
        </li>
      </ul>

      <div onClick={handleNav} className="block lg:hidden z-50">
        {nav ? (
          <AiOutlineClose size={30} className="text-[#0F58CE]" />
        ) : (
          <AiOutlineMenu
            size={30}
            className={`${isHomePage ? "text-[#0F58CE]" : "text-white"}`}
          />
        )}
      </div>

      <ul
        className={
          nav
            ? "fixed lg:hidden pt-16 left-0 top-0 w-[80%] h-full border-r-white bg-gray-50 ease-in-out duration-500 z-40"
            : "ease-in-out w-full duration-500 fixed top-0 left-[-100%]"
        }
      >
        {navItems.map((item) => (
          (item.id !== 6 && isLogged) || (item.id !== 7 && !isLogged) ? (
            <li
              key={item.id}
              onClick={() => {
                handleNav();
                item.id === 7 && localStorage.removeItem("id");
                navigate(item.id === 7 ? "/" : item.to);
              }}
              className={`p-4 border-b border-gray-100 rounded-xl duration-300 text-[#0F58CE] cursor-pointer ${location.pathname === item.to
                ? "bg-[#0F58CE] text-white"
                : "text-[#0F58CE]"
                }`}
            >
              {item.text}
            </li>
          ) : (
            ""
          )
        ))}

        <li className="p-4 border-b border-gray-100 rounded-xl duration-300 cursor-pointer text-[#0F58CE]">
          <button
            onClick={toggleContactDropdown}
            className="bg-[#0F58CE] border-2 border-white text-white w-full sm:w-auto flex items-center justify-center px-6 py-3 rounded-lg text-lg hover:bg-white hover:text-[#0F58CE] font-bold transition-colors duration-300 ease-in-out"
          >
            تواصل معنا
          </button>
          {showContactDropdown && (
            <div className="mt-2 bg-white text-[#0F58CE] p-2 rounded shadow-lg">
              <a
                href="https://wa.me/+966553742479"
                className="block px-4 min-w-fit py-2 hover:bg-[#0F58CE] hover:text-white rounded"
              >
                هاتف المجمع
              </a>
              <a
                href="https://wa.me/+966506028424"
                className="block px-4 py-2 hover:bg-[#0F58CE] hover:text-white rounded"
              >
                عيادة الجلدية والليزر
              </a>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
