import React from "react";
import { useParams } from "react-router";

const Pending = () => {
  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <div className="w-16 h-16 border-4 border-t-4 border-[#0F58CE] border-t-transparent rounded-full animate-spin"></div>
      <h3 className="text-2xl font-bold text-[#0F58CE] mt-3">
        بالرجاء الانتظار ....
      </h3>
    </div>
  );
};
const Success = () => {
  return <div>success</div>;
};

const Canceled = () => {
  return <div>Canceled</div>;
};

export default function LoadingPage() {
  const params = useParams();
  switch (params.status) {
    case "pending":
      return <Pending />;
    case "success":
      return <Success />;
    case "canceled":
      return <Canceled />;
    default:
      return <Pending />;
  }
}
