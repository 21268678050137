import React from 'react'
import CalenderImage from '../assets/calendar.png'
import Background from '../assets/wave-haikei.png'
import { Link } from 'react-router-dom'
import { FiPhone } from 'react-icons/fi';  // Importing the phone icon
function Home({ organizationName }) {
  return (
    <div className="min-h-[calc(100vh-5rem)] position-relative text-center md:text-right flex justify-center  flex-col md:flex-row " style={{ background: `url(${Background})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>
      <div className=' mt-20 h-[max-content] p-4 w-[90%] md:w-[40%]   mx-auto  flex flex-col items-end' >
        <div>
          <h1 className="text-2xl xl:text-5xl mb-4 leading-5	 font-extrabold text-[white]">
            احجز  طبيبك الان <br></br>
          </h1>
          <h1 className="text-2xl xl:text-5xl p-1 leading-5	 font-extrabold text-[white]">
            اون لاين .
          </h1>
          <p className='text-md my-10 text-white leading-7  text-[16px]'>
            ضع صحتك على رأس أولوياتك وابدأ رحلة العافية معنا. خطط لزيارتك الطبية وحدد موعدك الإلكتروني بكل يسر وسهولة. انضم إلى قائمة عملائنا المميزين واحجز استشارتك مع نخبة من أمهر الأطباء. صحتك هي أولويتنا القصوى – احجز الآن!.
          </p>
          <div className='flex flex-col sm:flex-row gap-4 flex-wrap'>
            <Link to={'/services'} className="bg-white text-black w-full sm:w-auto flex items-center justify-center px-6 py-3 rounded-lg text-lg hover:bg-blue-700 hover:text-white font-bold transition-colors duration-300 ease-in-out">
              ابدأ الأن
            </Link>
            <a
              href="https://wa.me/+966553742479"
              rel="noopener noreferrer"
              className="bg-[#0F58CE] border-2 border-white text-white w-full sm:w-auto flex items-center justify-center px-6 py-3 rounded-lg text-lg hover:bg-white hover:text-blue-600 font-bold transition-colors duration-300 ease-in-out"
            >
               هاتف المجمع
              <FiPhone className="mr-2" />
            </a>
            <a
              href="https://wa.me/+966506028424"
              rel="noopener noreferrer"
              className="bg-[#0F58CE] border-2 border-white text-white w-full sm:w-auto flex items-center justify-center px-6 py-3 rounded-lg text-lg hover:bg-white hover:text-blue-600 font-bold transition-colors duration-300 ease-in-out"
            >
               هاتف اللليزر والجلدية
              <FiPhone className="mr-2" />
            </a>
          </div>
        </div>

      </div>

      <div className=' items-center justify-center    md:flex '>
        <img src={CalenderImage} alt="calender" className="h-[800px] max-h-[60vw] mx-auto mt-[-100px] " />
      </div>
      <div style={{position:'absolute'}} className='position-absolute bottom-[20px] text-[18px] text-white'>
      Powered By Marshalsoft.com
      </div>
    </div>
  )
}

export default Home 