import ar from "date-fns/locale/ar";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router";
import MarshalUrl from "../API/config";

function Calender({
  setSelectDate,
  selectDoctor,
  selectClinic,
  books,
  user,
  setBook,
  setIsValidStep,
  selectDate,
}) {
  const navigate = useNavigate();
  const {
    register,
    setValue,
    trigger,
    formState: { errors, defaultValues },
  } = useFormContext();

  if (!user || !selectDoctor || !selectClinic) {
    navigate("/reservation_id");
  }

  const [book, setBookHere] = useState(books);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectTime, setSelectTime] = useState(null);
  const [dateSelectedWithPM_Am, SetDateSelectedWithPM_Am] = useState(false);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(null);
  const [toggleTime, setToggleTime] = useState("صباحا");
  const [periodsApi, setPeriodsApi] = useState([]);

  useEffect(() => {
    const periods = async () => {
      const { data } = await MarshalUrl.get(`/doctors/${selectDoctor.value}/periods`);
      setPeriodsApi(data);
    };
    periods();
  }, [selectDoctor]);

  const handleTimeChange = (time) => {
    let x = new Date(time);
    x.setHours(x.getHours() + 2);
    setSelectTime(x);
    setSelectDate((prev) => ({ ...prev, time: x }));
    setValue("calenderStep.selectDate", x);
    trigger("calenderStep.selectDate");
  };

  useEffect(() => {
    const validateStep = async () => {
      const isValid = await trigger("calenderStep.selectDate");
      setIsValidStep(isValid);
    };
    validateStep();
  }, [selectTime, trigger, setIsValidStep]);

  const parseTimeString = (timeString) => {
    const [time, modifier] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
  
    if (modifier === "PM" && hours < 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    const date = new Date("2000-01-01");
    date.setHours(hours, minutes, 0, 0);
    return date;
  };
  
  const generateTimeSlots = (fromTime, toTime, interval) => {
    const slots = [];
    let startTime = parseTimeString(fromTime);
    const endTime = parseTimeString(toTime);
  
    while (startTime < endTime) {
      slots.push(new Date(startTime));
      startTime = new Date(startTime.getTime() + interval * 60000);
    }
  
    return slots;
  };

  const filterAndGenerateSlots = (period) => {
    const periodFromTime = period.period_from;
    const periodToTime = period.period_to;
    const timeBetween = period.time_between;

    const slots = generateTimeSlots(periodFromTime, periodToTime, timeBetween);

    return slots.filter((slot) => {
      const hours = slot.getHours();
      if (toggleTime === "صباحا" && hours < 12) {
        return true;
      } else if (toggleTime === "مساء" && hours >= 12) {
        return true;
      }
      return false;
    });
  };

  const timeSlots = periodsApi.flatMap(filterAndGenerateSlots);

  useEffect(() => {
    if (selectDate?.timeAMPM) {
      const newTime = new Date("2000-01-01");
      const [timeHour, timeMinutes] = selectDate?.timeAMPM?.split(":");
      const hours =
        parseInt(timeHour, 10) +
        (selectDate?.timeAMPM.includes("م") && parseInt(timeHour, 10) !== 12
          ? 12
          : 0);
      newTime.setHours(hours);
      newTime.setMinutes(parseInt(timeMinutes, 10));
      newTime.setSeconds(0);
      handleTimeChange(newTime);
    }

    if (selectDate?.timeAMPM.includes("م")) {
      setToggleTime("مساء");
    } else if (selectDate?.timeAMPM.includes("ص")) {
      setToggleTime("صباحا");
    }
  }, []);

  console.log(timeSlots)
  return (
    <div className="flex h-[450px] max-w-[95%] w-[700px] m-auto text-white">
      <div className="flex items-center justify-center flex-col w-full md:w-[100%] bg-blue-800 rounded-2xl p-5">
        <h1 className="text-3xl my-6 text-center">اختر الوقت المناسب لك</h1>
        <div className="flex justify-evenly items-center w-full flex-wrap gap-2">
          <DatePicker
            selected={selectedDate}
            onChange={(date) => {
              setSelectDate(null)
              setSelectedTimeIndex(null);
              SetDateSelectedWithPM_Am(null);
              setSelectDate(null);
              setIsValidStep(false);
              setValue("calenderStep.selectDate", date);
              trigger("calenderStep.selectDate");
            }}
            locale={ar}
            minDate={new Date()}
            dateFormat="dd/MM/yyyy"
            className="bg-white shadow-md font-bold text-[#1E56A0] px-4 py-2 rounded-full border border-3 border-black text-center"
          />

          <div className="bg-white rounded-full p-1 ">
            <button
              onClick={() => setToggleTime("مساء")}
              className={`${toggleTime === "مساء" ? "bg-blue-800 text-white shadow-md" : ""
                }  font-bold text-[#1E56A0] px-4 py-2 rounded-full`}
            >
              مساء
            </button>
            <button
              onClick={() => setToggleTime("صباحا")}
              className={`${toggleTime === "صباحا" ? "bg-blue-800 text-white shadow-md" : ""
                }  font-bold text-[#1E56A0] px-4 py-2 rounded-full`}
            >
              صباحا
            </button>
          </div>
        </div>
        <div className="mt-4 overflow-auto w-[100%] mx-auto text-center">
          {timeSlots.length === 0 ? (
            <div className="text-red-500 text-xl font-bold">
              {toggleTime === "مساء" ? "لا يو فترات مسائيه" : "لا يو فترات صباحيه"}
            </div>
          ) : (
            timeSlots.map((time, index) => {
              const hour = time.getHours() % 12 || 12;
              const minute = time.getMinutes().toString().padStart(2, "0");
              const period = time.getHours() < 12 ? "ص" : "م";
              const timeString = `${hour.toString().padStart(2, "0")}:${minute} ${period}`;

              const matchingTime =
                selectDate?.timeAMPM === timeString;
              const isDisabled = book?.some((book) => {
                const date = new Date(selectedDate);
                const bookDate = new Date(book[1]);
                if (
                  book[6] === selectDoctor?.value &&
                  date.toDateString() === bookDate.toDateString()
                ) {
                  const bookedTime = new Date(book[2]);
                  bookedTime.setHours(bookedTime.getUTCHours() + 3);
                  const bookedTimeString = `${(bookedTime.getHours() % 12 || 12)
                    .toString()
                    .padStart(2, "0")}:${bookedTime
                      .getUTCMinutes()
                      .toString()
                      .padStart(2, "0")} ${bookedTime.getHours() < 12 ? "ص" : "م"}`;
                  return bookedTimeString === timeString;
                }
              });

              return (
                <div
                  key={index}
                  className={`p-2 m-1 bg-white text-blue-800 rounded-md cursor-pointer w-[90px] inline-block ${selectedTimeIndex === index || matchingTime
                    ? "bg-yellow-200 text-blue-950 border-black border-double border-[2px] shadow-md"
                    : isDisabled
                      ? "opacity-50 pointer-events-none"
                      : "hover:bg-blue-200"
                    }`}
                  onClick={() => {
                    if (!isDisabled) {
                      setSelectedTimeIndex(index);
                      SetDateSelectedWithPM_Am(timeString);
                      const adjustedDate = new Date(selectedDate);
                      setSelectDate({
                        date: adjustedDate.toISOString().split("T")[0],
                        time: selectTime,
                        timeAMPM: timeString,
                      });
                      handleTimeChange(time);
                    }
                  }}
                >
                  {timeString}
                </div>
              );
            })
          )}
        </div>
        {errors.calenderStep?.selectDate && (
          <span className="text-red-500">
            {errors.calenderStep.selectDate.message}
          </span>
        )}
      </div>
    </div>
  );
}

export default Calender;
