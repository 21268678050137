import React, { useState } from 'react';
import MarshalUrl, { BaseUrl } from '../API/config';

function Payment() {
    // State for customer and cart information
    const [customer, setCustomer] = useState({ name: '', email: '' });
    const [cart, setCart] = useState({ id: '1', currency: 'EGP', amount: '100', description: 'Product Description' });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    // Update customer details
    const handleCustomerChange = (e) => {
        const { name, value } = e.target;
        setCustomer(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Update cart details
    const handleCartChange = (e) => {
        const { name, value } = e.target;
        setCart(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await MarshalUrl.post('/pay', {
                cart,
                customer,
                url: { callback: "https://reservation-server-rktp.onrender.com/payment/callback", response: `${BaseUrl}/payment/return` }
            });
            const data = response.data;
            if (data.url) {
                // Redirect user to PayTabs payment page
                window.location.href = data.url;
            } else {
                setError('Payment setup failed. Please try again.');
            }
        } catch (error) {
            setError('Error processing payment: ' + error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Payment Form</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Customer Name:</label>
                    <input type="text" name="name" value={customer.name} onChange={handleCustomerChange} required />
                </div>
                <div>
                    <label>Customer Email:</label>
                    <input type="email" name="email" value={customer.email} onChange={handleCustomerChange} required />
                </div>
                <div>
                    <label>Cart ID:</label>
                    <input type="text" name="id" value={cart.id} onChange={handleCartChange} />
                </div>
                <div>
                    <label>Currency:</label>
                    <input type="text" name="currency" value={cart.currency} onChange={handleCartChange} />
                </div>
                <div>
                    <label>Amount:</label>
                    <input type="number" name="amount" value={cart.amount} onChange={handleCartChange} />
                </div>
                <div>
                    <label>Description:</label>
                    <input type="text" name="description" value={cart.description} onChange={handleCartChange} />
                </div>
                <button type="submit" disabled={loading}>Pay Now</button>
            </form>
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
}

export default Payment;
